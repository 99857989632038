import DotaTeam from "../assets/img/webp/gallery/poze-lan-1.webp";
import F1 from "../assets/img/webp/gallery/poze-lan-5.webp";
import F1Pov from "../assets/img/webp/gallery/poze-lan-6.webp";
import Vr from "../assets/img/webp/gallery/poze-lan-7.webp";
import GuyGaming from "../assets/img/webp/gallery/poze-lan-8.webp";
import WideShot from "../assets/img/webp/gallery/poze-lan-9.webp";
import LolTeam from "../assets/img/webp/gallery/poze-lan-13.webp";

export const slides = [
  {
    url: DotaTeam,
  },
  {
    url: F1,
  },
  {
    url: F1Pov,
  },
  {
    url: Vr,
  },
  {
    url: GuyGaming,
  },
  {
    url: WideShot,
  },
  {
    url: LolTeam,
  },
];
